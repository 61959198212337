exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-capital-projects-research-tsx": () => import("./../../../src/pages/capital-projects-research.tsx" /* webpackChunkName: "component---src-pages-capital-projects-research-tsx" */),
  "component---src-pages-climate-action-tsx": () => import("./../../../src/pages/climate-action.tsx" /* webpackChunkName: "component---src-pages-climate-action-tsx" */),
  "component---src-pages-corporate-social-responsibility-tsx": () => import("./../../../src/pages/corporate-social-responsibility.tsx" /* webpackChunkName: "component---src-pages-corporate-social-responsibility-tsx" */),
  "component---src-pages-diversity-tsx": () => import("./../../../src/pages/diversity.tsx" /* webpackChunkName: "component---src-pages-diversity-tsx" */),
  "component---src-pages-en-case-study-amigos-do-bem-tsx": () => import("./../../../src/pages/en/case-study/amigos-do-bem.tsx" /* webpackChunkName: "component---src-pages-en-case-study-amigos-do-bem-tsx" */),
  "component---src-pages-en-case-study-cure-tsx": () => import("./../../../src/pages/en/case-study/cure.tsx" /* webpackChunkName: "component---src-pages-en-case-study-cure-tsx" */),
  "component---src-pages-en-case-study-door-of-hope-tsx": () => import("./../../../src/pages/en/case-study/door-of-hope.tsx" /* webpackChunkName: "component---src-pages-en-case-study-door-of-hope-tsx" */),
  "component---src-pages-en-case-study-naccho-tsx": () => import("./../../../src/pages/en/case-study/naccho.tsx" /* webpackChunkName: "component---src-pages-en-case-study-naccho-tsx" */),
  "component---src-pages-en-case-study-oxfam-trailwalker-tsx": () => import("./../../../src/pages/en/case-study/oxfam-trailwalker.tsx" /* webpackChunkName: "component---src-pages-en-case-study-oxfam-trailwalker-tsx" */),
  "component---src-pages-en-case-study-rays-of-hope-tsx": () => import("./../../../src/pages/en/case-study/rays-of-hope.tsx" /* webpackChunkName: "component---src-pages-en-case-study-rays-of-hope-tsx" */),
  "component---src-pages-en-case-study-redco-tsx": () => import("./../../../src/pages/en/case-study/redco.tsx" /* webpackChunkName: "component---src-pages-en-case-study-redco-tsx" */),
  "component---src-pages-en-case-study-rspca-tsx": () => import("./../../../src/pages/en/case-study/rspca.tsx" /* webpackChunkName: "component---src-pages-en-case-study-rspca-tsx" */),
  "component---src-pages-en-case-study-schoolsplus-tsx": () => import("./../../../src/pages/en/case-study/schoolsplus.tsx" /* webpackChunkName: "component---src-pages-en-case-study-schoolsplus-tsx" */),
  "component---src-pages-en-case-study-solarbuddy-tsx": () => import("./../../../src/pages/en/case-study/solarbuddy.tsx" /* webpackChunkName: "component---src-pages-en-case-study-solarbuddy-tsx" */),
  "component---src-pages-en-climate-action-tsx": () => import("./../../../src/pages/en/climate-action.tsx" /* webpackChunkName: "component---src-pages-en-climate-action-tsx" */),
  "component---src-pages-en-corporate-social-responsibility-tsx": () => import("./../../../src/pages/en/corporate-social-responsibility.tsx" /* webpackChunkName: "component---src-pages-en-corporate-social-responsibility-tsx" */),
  "component---src-pages-en-diversity-tsx": () => import("./../../../src/pages/en/diversity.tsx" /* webpackChunkName: "component---src-pages-en-diversity-tsx" */),
  "component---src-pages-en-insights-tsx": () => import("./../../../src/pages/en/insights.tsx" /* webpackChunkName: "component---src-pages-en-insights-tsx" */),
  "component---src-pages-en-insights-v-1-tsx": () => import("./../../../src/pages/en/insights-v1.tsx" /* webpackChunkName: "component---src-pages-en-insights-v-1-tsx" */),
  "component---src-pages-en-our-social-values-tsx": () => import("./../../../src/pages/en/our-social-values.tsx" /* webpackChunkName: "component---src-pages-en-our-social-values-tsx" */),
  "component---src-pages-en-pipware-old-tsx": () => import("./../../../src/pages/en/pipware-old.tsx" /* webpackChunkName: "component---src-pages-en-pipware-old-tsx" */),
  "component---src-pages-en-press-tsx": () => import("./../../../src/pages/en/press.tsx" /* webpackChunkName: "component---src-pages-en-press-tsx" */),
  "component---src-pages-en-press-v-1-tsx": () => import("./../../../src/pages/en/press-v1.tsx" /* webpackChunkName: "component---src-pages-en-press-v-1-tsx" */),
  "component---src-pages-en-press-v-2-tsx": () => import("./../../../src/pages/en/press-v2.tsx" /* webpackChunkName: "component---src-pages-en-press-v-2-tsx" */),
  "component---src-pages-en-press-v-3-tsx": () => import("./../../../src/pages/en/press-v3.tsx" /* webpackChunkName: "component---src-pages-en-press-v-3-tsx" */),
  "component---src-pages-en-reasearch-tsx": () => import("./../../../src/pages/en/reasearch.tsx" /* webpackChunkName: "component---src-pages-en-reasearch-tsx" */),
  "component---src-pages-en-research-[slug]-tsx": () => import("./../../../src/pages/en/research/[slug].tsx" /* webpackChunkName: "component---src-pages-en-research-[slug]-tsx" */),
  "component---src-pages-en-subscribe-tsx": () => import("./../../../src/pages/en/subscribe.tsx" /* webpackChunkName: "component---src-pages-en-subscribe-tsx" */),
  "component---src-pages-en-testing-wiredup-tsx": () => import("./../../../src/pages/en/testing-wiredup.tsx" /* webpackChunkName: "component---src-pages-en-testing-wiredup-tsx" */),
  "component---src-pages-en-thankyou-tsx": () => import("./../../../src/pages/en/thankyou.tsx" /* webpackChunkName: "component---src-pages-en-thankyou-tsx" */),
  "component---src-pages-en-wiredup-business-model-tsx": () => import("./../../../src/pages/en/wiredup_business_model.tsx" /* webpackChunkName: "component---src-pages-en-wiredup-business-model-tsx" */),
  "component---src-pages-en-wiredup-business-performance-management-tsx": () => import("./../../../src/pages/en/wiredup-business-performance-management.tsx" /* webpackChunkName: "component---src-pages-en-wiredup-business-performance-management-tsx" */),
  "component---src-pages-en-wiredup-change-management-software-tsx": () => import("./../../../src/pages/en/wiredup-change-management-software.tsx" /* webpackChunkName: "component---src-pages-en-wiredup-change-management-software-tsx" */),
  "component---src-pages-en-wiredup-diagnostic-and-analysis-tsx": () => import("./../../../src/pages/en/wiredup-diagnostic-and-analysis.tsx" /* webpackChunkName: "component---src-pages-en-wiredup-diagnostic-and-analysis-tsx" */),
  "component---src-pages-en-wiredup-esg-management-software-tsx": () => import("./../../../src/pages/en/wiredup-esg-management-software.tsx" /* webpackChunkName: "component---src-pages-en-wiredup-esg-management-software-tsx" */),
  "component---src-pages-en-wiredup-idea-management-software-tsx": () => import("./../../../src/pages/en/wiredup-idea-management-software.tsx" /* webpackChunkName: "component---src-pages-en-wiredup-idea-management-software-tsx" */),
  "component---src-pages-en-wiredup-organisational-transformation-tsx": () => import("./../../../src/pages/en/wiredup-organisational-transformation.tsx" /* webpackChunkName: "component---src-pages-en-wiredup-organisational-transformation-tsx" */),
  "component---src-pages-en-wiredup-transformation-tsx": () => import("./../../../src/pages/en/wiredup_transformation.tsx" /* webpackChunkName: "component---src-pages-en-wiredup-transformation-tsx" */),
  "component---src-pages-en-wireup-1-tsx": () => import("./../../../src/pages/en/wireup1.tsx" /* webpackChunkName: "component---src-pages-en-wireup-1-tsx" */),
  "component---src-pages-energy-transition-research-tsx": () => import("./../../../src/pages/energy-transition-research.tsx" /* webpackChunkName: "component---src-pages-energy-transition-research-tsx" */),
  "component---src-pages-graph-cms-case-study-locale-case-study-graph-cms-case-study-slug-tsx": () => import("./../../../src/pages/{GraphCmsCaseStudy.locale}/case-study/{GraphCmsCaseStudy.slug}.tsx" /* webpackChunkName: "component---src-pages-graph-cms-case-study-locale-case-study-graph-cms-case-study-slug-tsx" */),
  "component---src-pages-graph-cms-case-study-locale-test-1-tsx": () => import("./../../../src/pages/{GraphCmsCaseStudy.locale}/test1.tsx" /* webpackChunkName: "component---src-pages-graph-cms-case-study-locale-test-1-tsx" */),
  "component---src-pages-graph-cms-case-study-locale-test-tsx": () => import("./../../../src/pages/{GraphCmsCaseStudy.locale}/test.tsx" /* webpackChunkName: "component---src-pages-graph-cms-case-study-locale-test-tsx" */),
  "component---src-pages-graph-cms-insight-locale-insight-graph-cms-insight-slug-tsx": () => import("./../../../src/pages/{GraphCmsInsight.locale}/insight/{GraphCmsInsight.slug}.tsx" /* webpackChunkName: "component---src-pages-graph-cms-insight-locale-insight-graph-cms-insight-slug-tsx" */),
  "component---src-pages-graph-cms-person-locale-people-graph-cms-person-slug-tsx": () => import("./../../../src/pages/{GraphCmsPerson.locale}/people/{GraphCmsPerson.slug}.tsx" /* webpackChunkName: "component---src-pages-graph-cms-person-locale-people-graph-cms-person-slug-tsx" */),
  "component---src-pages-graph-cms-story-locale-story-graph-cms-story-slug-tsx": () => import("./../../../src/pages/{GraphCmsStory.locale}/story/{GraphCmsStory.slug}.tsx" /* webpackChunkName: "component---src-pages-graph-cms-story-locale-story-graph-cms-story-slug-tsx" */),
  "component---src-pages-our-social-values-tsx": () => import("./../../../src/pages/our-social-values.tsx" /* webpackChunkName: "component---src-pages-our-social-values-tsx" */),
  "component---src-pages-search-tsx": () => import("./../../../src/pages/search.tsx" /* webpackChunkName: "component---src-pages-search-tsx" */),
  "component---src-pages-social-page-3-tsx": () => import("./../../../src/pages/social-page-3.tsx" /* webpackChunkName: "component---src-pages-social-page-3-tsx" */),
  "component---src-templates-ga-press-tsx": () => import("./../../../src/templates/gaPress.tsx" /* webpackChunkName: "component---src-templates-ga-press-tsx" */),
  "component---src-templates-page-tsx": () => import("./../../../src/templates/page.tsx" /* webpackChunkName: "component---src-templates-page-tsx" */),
  "component---src-templates-sector-page-tsx": () => import("./../../../src/templates/sectorPage.tsx" /* webpackChunkName: "component---src-templates-sector-page-tsx" */)
}

